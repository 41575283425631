import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box, StepLabel, StepContent, Link } from '@mui/material'
import { ModelAutocompleteField, DateField, ModelMenuSelectorField, SingleOptionSelectorField, TextField } from '@front/squirtle'

const Row1 = ({ brand, cleanModel, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={1}>
      <SingleOptionSelectorField
        name='active'
        clearOnEmpty={false}
        label={I18n.t('status.active')}
      />
    </Grid>
    <Grid item xs={2}>
      <TextField
        label={I18n.t('vehicle.plaque')}
        name='info.plaque'
        required
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        label={I18n.t('vehicle.brand', { count: 1 })}
        name='info.brand'
        model_name={'vehiclebrand'}
        labelKeys={['name']}
        autocompleteKey={'name'}
        searchMode={'search'}
        searchQuery={'startsWith'}
        loadOnFocus
        afterChange={cleanModel}
        clearOnEmpty
        selectFirst={false}
        creatable
        createKey={['name']}
      />

    </Grid>
    <Grid item xs hidden={!brand}>
      <ModelAutocompleteField
        label={I18n.t('vehicle.model', { count: 1 })}
        name='info.model'
        model_name={'vehiclemodel'}
        labelKeys={['name']}
        autocompleteKey={'name'}
        searchMode={'search'}
        searchQuery={'startsWith'}
        config={{
          forced_filter: { brand },
          validate_filter: useCallback(() => !!brand, [brand]),
          watchers: [brand]
        }}
        selectFirst={false}
        required
        disabled={!brand}
        clearOnEmpty
        creatable
        createKey={['name']}
      />

    </Grid>
    <Grid item xs={1}>
      <TextField
        label={I18n.t('vehicle.color')}
        name='info.color'
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        label={I18n.t('vehicle.max_load')}
        name='passenger_capacity'
        type="number"
        InputProps={{
          inputProps: {
            style: { textAlign: 'right' }
          }
        }}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        label={I18n.t('vehicle.max_luggage_load')}
        name='passenger_luggage_capacity'
        type="number"
        InputProps={{
          inputProps: {
            style: { textAlign: 'right' }
          }
        }}
        required
      />
    </Grid>
  </Grid>

const Row2 = ({ isManagingRecord, group, isReadOnly, TAXI_ENABLED, PACKAGE_ENABLED, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={2}>
      <ModelMenuSelectorField
        label={I18n.t('vehicle.fleet')}
        name='fleet'
        model_name='fleet'
        loadOnFocus
        searchMode='search'
        labelKeys={['name']}
        allowNone={false}
        config={{
          forced_filter: { load_type: _.compact([!!TAXI_ENABLED && 'user', !!PACKAGE_ENABLED && 'package']) },
          validate_filter: useCallback(() => !!TAXI_ENABLED || !!PACKAGE_ENABLED, [TAXI_ENABLED, PACKAGE_ENABLED]),
          watchers: [TAXI_ENABLED, PACKAGE_ENABLED]
        }}
        required
      />
    </Grid>
    <Grid item xs={2} hidden={!isManagingRecord}>
      <ModelMenuSelectorField
        label={I18n.t('contract.id')}
        name='parent_license'
        model_name='license'
        loadOnFocus
        searchMode='search'
        labelKeys={['license_client_id']}
        config={{
          forced_filter: { group, usual_vehicle: null },
          validate_filter: useCallback(() => !!group, [group]),
          watchers: [group]
        }}
        getOptionLabel={(option, type) => !!group && option?.license_id && type === 'value'
          ? <Link href={`/${group}/license/${option?.license_id}`} target={'_blank'}>
            {option?.license_client_id}
          </Link>
          : option?.license_client_id
        }
      />
    </Grid>
    <Grid item xs>
      <TextField label={I18n.t('vehicle.comment')} name='info.comment' />
    </Grid>
  </Grid>

const Row3 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <DateField
        label={I18n.t('vehicle.in_service')}
        name='info.in_service_date'
        inputFormat="DD/MM/YYYY"
        required
      />
    </Grid>
    <Grid item xs>
      <DateField
        label={I18n.t('vehicle.last_control')}
        name='info.op_control_date'
        inputFormat="DD/MM/YYYY"
        required
      />
    </Grid>
    <Grid item xs>
      <DateField
        label={I18n.t('vehicle.period_insurance')}
        name='info.insurance_date'
        inputFormat="DD/MM/YYYY"
        required
      />
    </Grid>
    <Grid item xs>
      <DateField
        label={I18n.t('vehicle.period_control')}
        name='info.safety_inspection_date'
        inputFormat='DD/MM/YYYY'
        required
      />
    </Grid>
  </Grid>


const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('vehicle.global_informations')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
      <Grid container rowSpacing={2} >
        <Row1 {...props} />
        <Row2 {...props} />
        <Row3 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
